/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
window['__Zone_disable_customElements'] = true;

/**
 * Optimize non-passive events added to scroll-blocking events.
 */
window['__zone_symbol__PASSIVE_EVENTS'] = ['touchmove', 'touchstart'];
